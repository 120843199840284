// src/components/HomePage.js
import React from 'react';
import '../css/Home.css';

const Home = () => {
  return (
    <div className="home-page">
      <div className="content-container">
        <div className="left-box">
          <h2 className="left-box-title">Project Management Skills</h2>
          
          <ul className="skills-list">
            <li>Agile Project Management</li>
            <li>Cost Estimation & Budgeting</li>
            <li>Risk Management Strategies</li>
            <li>Resource Allocation Optimization</li>
            <li>Stakeholder Communication</li>
          </ul>
        </div>
        <div className="right-box">
          <h2 className="right-box-title">Technical Skills</h2>
          <div class="inner-box">
            <ul className="technical-skills-list">
              <li>
                <strong>Frontend Development:</strong>
                  <ul>
                    <li>React.js</li>
                    <li>JavaScript (ES6+)</li>
                    <li>CSS3 & Preprocessors </li>
                    <li>HTML5</li>
                 </ul>
              </li>
              <li>
                <strong>Backend & APIs:</strong>
                  <ul>
                    <li>Node.js & Express.js</li>
                    <li>RESTful APIs</li>
                    <li>GraphQL</li>
                    <li>Third-Party APIs (Google Maps, Stripe, etc.)</li>
                  </ul>
              </li>
              <li>
                <strong>Version Control:</strong>
                  <ul>
                    <li>Git & GitHub</li>
                    <li>Branching and Merging</li>
                    <li>Pull Requests</li>
                    <li>Continuous Integration (CI/CD)</li>
                  </ul>
              </li>
              <li>
                <strong>Database & Authentication:</strong>
                  <ul>
                    <li>Firebase</li>
                    <li>Supabase</li>
                    <li>MongoDB</li>
                    <li>SQL & PostgreSQL</li>
                  </ul>
              </li>
              <li>
                <strong>Full-Stack React.js:</strong>
                  <ul>
                    <li>Redux & Context API</li>
                    {/* <li>Next.js (SSR & SSG)</li> */}
                    <li>React Router</li>
                    <li>Axios & Fetch API</li>
                  </ul>
              </li>
              <li>
                <strong>APIs/SDKs:</strong>
                  <ul>
                    <li>Firebase SDK</li>
                    <li>Supabase SDK</li>
                    <li>Google Cloud API</li>
                    <li>Stripe API</li>
                  </ul>
              </li>
            </ul>
          </div>  
        </div>
      </div>
    </div>
  );
};

export default Home;
